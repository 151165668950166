<template>
  <div class="youth-attendance-summary" v-if="hasPerm('youth.view_seanceinscription')">
    <div ref="printMe">
      <page-header
        :title="'Fréquentation ' + youthHomeLabel + ' - liste des inscrits'"
        icon="fas fa-th-list"
        :links="getLinks()"
      >
      </page-header>
      <div ref="excelMe">
        <div class="header-bar" v-show="showList">
          <b-row>
            <b-col class="no-print">
              <seances-list-filter
                @changed="onListFilterChanged($event)"
                @loaded="onListFilterLoaded($event)"
                multi
              >
              </seances-list-filter>
            </b-col>
            <b-col cols="4" class="text-right">
              <div class="field-box">
                <div class="field-box-title">Affichage</div>
                <b-form-checkbox v-model="showAddress">Adresse</b-form-checkbox>
                <b-form-checkbox v-if="showForceCity" v-model="useForcedCity">Ville de facturation</b-form-checkbox>
                <b-form-checkbox v-model="showBirthDate">Date de naissance</b-form-checkbox>
                <b-form-checkbox v-model="showEmailCol">Email</b-form-checkbox>
                <b-form-checkbox v-model="showCellPhoneCol">Tel. portable</b-form-checkbox>
                <check-box-select
                  inline
                  id="moments"
                  :choices="moments"
                  :initial-value="moments"
                  @changed="onMomentsChanged"
                  @init="onMomentsChanged"
                >
                </check-box-select>
              </div>
              <div class="field-box">
                <div class="field-box-title">Filtres</div>
                <date-frame-selector @change="onDateFrameChanged">
                </date-frame-selector>
                <div v-if="seanceFilters.length">
                  <b-form-select v-model="selectedSeanceFilter" id="seance-filter" @change="onSeanceFilterChanged()">
                    <b-select-option v-for="elt of seanceFilters" :key="elt.id" :value="elt">
                      {{ elt.name }}
                    </b-select-option>
                  </b-form-select>
                </div>
                <div class="custom-range-holder no-print">
                  <div><b>Tranches de QF</b></div>
                  <b-row>
                    <b-col>
                      <number-input v-model="familyLevel1" id="familyLevel1" allow-null @focusout="loadData()">
                      </number-input>
                    </b-col>
                    <b-col>
                      <number-input v-model="familyLevel2" id="familyLevel2" allow-null @focusout="loadData()">
                      </number-input>
                    </b-col>
                  </b-row>
                </div>
                <br />
                <b-form-group id="include-group" description="Cochez pour inclure ces inscriptions">
                  <b-form-checkbox
                    id="absence"
                    inline
                    v-model="absence"
                    @change="onSeanceFilterChanged()"
                  >
                    Absences
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="cancellations"
                    inline
                    v-model="cancellations"
                    @change="onSeanceFilterChanged()"
                  >
                    Annulations
                  </b-form-checkbox>
                  <br />
                  <b-form-checkbox
                    id="unconfirmed"
                    inline
                    v-model="unconfirmed"
                    @change="onSeanceFilterChanged()"
                  >
                    À confirmer
                  </b-form-checkbox>
                  <b-form-checkbox
                    inline
                    id="refused"
                    v-model="refused"
                    @change="onSeanceFilterChanged()"
                  >
                    Refus
                  </b-form-checkbox>
                  <b-form-checkbox
                    inline
                    id="waiting"
                    v-model="waiting"
                    @change="onSeanceFilterChanged()"
                  >
                    En attente
                  </b-form-checkbox>
                  <b-form-checkbox
                    id="all"
                    inline
                    v-model="all"
                    @change="onSeanceFilterChanged()"
                  >
                    Sans inscriptions
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group id="fixedFee-group">
                  <br />
                  <b-form-checkbox
                    inline
                    id="fixedFee"
                    v-model="fixedFee"
                    @change="onSeanceFilterChanged()"
                  >
                    Forfaits
                  </b-form-checkbox>
                </b-form-group>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Code séance"
                      label-for="'seanceCode"
                      description="Entrez le code pour filtrer les séances. Séparez les codes par des virgules"
                    >
                      <div class="text-left small" style="margin-top: -25px">
                        <a href @click.prevent="noop()">
                          <i class="fa fa-refresh"></i> MAJ</a>
                      </div>
                      <b-form-input
                        id="seanceCode"
                        v-model="seanceCodeFilter"
                        @change="loadData()"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Nom d'atelier"
                      :label-for="'workshops'"
                      description="Entrez les noms d'atelier séparés par des virgules"
                    >
                      <div class="text-left small" style="margin-top: -25px">
                        <a href @click.prevent="noop()">
                          <i class="fa fa-refresh"></i> MAJ</a>
                      </div>
                      <b-form-input
                        id="workshops"
                        v-model="workshopsFilter"
                        @change="loadData()"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row v-show="showList">
          <b-col>
            <loading-gif :loading-name="loadingName"></loading-gif>
            <div v-if="!isLoading(loadingName) && isValid">
              <x-table
                :columns="columns"
                :items="items"
                verbose-name="inscrit"
                :show-counter="true"
              >
              </x-table>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="select-box" v-if="!isLoading(loadingName)" v-show="!showList">
        <b-row>
          <b-col>
          </b-col>
          <b-col cols="3">
            <b-form-group
              v-if="showEmailsList"
              label="Taille des groupes d'emails"
              description="Attention! Un nombre trop grand peut générer des envois en spam"
            >
              <b-form-input
                type="number"
                v-model="emailsNumber"
                min="25"
                max="100"
                id="emailsNumber"
                class="small-text"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3" v-if="showCellPhonesList">
            <sms-list-operator-selector @change="smsSuffix = $event.smsSuffix" small-text>
            </sms-list-operator-selector>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Séparateur"
              class="small-text"
              description="Les éléments seront séparés par ce caractère"
            >
              <emails-groups-separator-selector
                @change="selectedSeparator = $event.separator"
                small-text
              >
              </emails-groups-separator-selector>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="selectedSeparator && showEmailsList">
        <b><counter-label :counter="emails.length" label="e-mail"></counter-label></b>
        <emails-groups :emails="emails" :separator="selectedSeparator.value" :emails-number="emailsNumber">
        </emails-groups>
      </div>
      <div v-if="selectedSeparator && showCellPhonesList">
        <b><counter-label :counter="mobilePhones.length" label="numéro"></counter-label></b>
        <sms-list
          :mobile-phones="mobilePhones"
          :separator="selectedSeparator.value"
          :sms-number="smsNumber"
          :suffix="smsSuffix"
        >
        </sms-list>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import PageHeader from '@/components/Layout/PageHeader'
import LoadingGif from '@/components/Controls/LoadingGif'
import XTable from '@/components/Controls/Table/Table'
import EmailsGroups from '@/components/Controls/EmailsGroups'
import CounterLabel from '@/components/Controls/CounterLabel'
import SmsList from '@/components/Controls/SmsList'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector'
import SeancesListFilter from '@/components/Seances/Config/SeancesListFilter'
import EmailsGroupsSeparatorSelector from '@/components/Controls/EmailsGroupsSeparatorSelector'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import store from '@/store'
import { makeAttendanceListItem } from '@/types/stats'
import { BackendApi, openDocument } from '@/utils/http'
import { makeChoice } from '@/types/base'
import { sum } from '@/utils/math'
import { distinctString, existsIn } from '@/utils/arrays'
import { cellPhoneForSms } from '@/utils/phones'
import SmsListOperatorSelector from '@/components/Controls/SmsListOperatorSelector.vue'
import NumberInput from '@/components/Controls/NumberInput.vue'

export default {
  name: 'youth-attendance-list',
  mixins: [BackendMixin],
  components: {
    NumberInput,
    SmsListOperatorSelector,
    EmailsGroupsSeparatorSelector,
    SmsList,
    CounterLabel,
    EmailsGroups,
    CheckBoxSelect,
    DateFrameSelector,
    XTable,
    LoadingGif,
    SeancesListFilter,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      statsLoading: 'youth-attendance',
      elements: [],
      youthHomes: [],
      seanceTypes: [],
      periods: [],
      loadingName: 'youth-attendance-summary',
      showBirthDate: false,
      showAddress: false,
      showEmailCol: false,
      showCellPhoneCol: false,
      startDate: null,
      endDate: null,
      seanceFilters: [],
      selectedSeanceFilter: null,
      selectedMoments: [],
      selectedSeparator: null,
      showEmailsList: false,
      showCellPhonesList: false,
      emailsNumber: 25,
      smsNumber: 0,
      smsSuffix: '',
      familyLevel1: null,
      familyLevel2: null,
      fixedFee: false,
      absence: false,
      all: false,
      cancellations: false,
      refused: false,
      waiting: false,
      unconfirmed: false,
      seanceCodeFilter: '',
      workshopsFilter: '',
      useForcedCity: false,
    }
  },
  computed: {
    isValid() {
      return (
        this.youthHomes.length && this.seanceTypes.length && this.periods.length
      )
    },
    showList() {
      return !(this.showEmailsList || this.showCellPhonesList)
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    emails() {
      return distinctString(this.items.filter(elt => elt.email).map(elt => elt.email))
    },
    showForceCity() {
      return store.getters.showForceCity
    },
    mobilePhones() {
      return distinctString(
        this.items.filter(
          elt => elt.cellPhone
        ).map(
          elt => cellPhoneForSms(elt.cellPhone)
        )
      )
    },
    columns() {
      let columns = [
        {
          name: 'lastName',
          label: 'Nom',
          onClick: item => {
            this.showEntitySidebar(item.entity)
          },
          isLink: item => {
            return (item.entityId)
          },
          linkUrl: item => {
            if (item.entityId) {
              return router.resolve({ name: 'families-detail', params: { entityId: item.entityId, }, }).href
            }
          },
        },
        {
          name: 'firstName',
          label: 'Prénom',
          onClick: item => {
            this.showIndividualSidebar(item.individual)
          },
          isLink: item => {
            return (item.entityId)
          },
          linkUrl: item => {
            if (item.entityId) {
              return router.resolve(
                {
                  name: 'families-detail',
                  params: { entityId: item.entityId, },
                  query: { individual: item.individual.id, },
                }
              ).href
            }
          },
        }
      ]
      if (this.showBirthDate) {
        columns.push(
          {
            name: 'birthDate',
            label: 'Naissance',
            dateFormat: 'DD/MM/YYYY',
          }
        )
      }
      if (this.showAddress) {
        columns.push(
          {
            name: 'address',
            label: 'Adresse',
          }
        )
        columns.push(
          {
            name: 'zipCode',
            label: 'Code postal',
          }
        )
      }
      columns = columns.concat(
        [
          {
            name: 'city',
            label: 'Ville',
          }
        ]
      )
      if (this.showForceCity && this.useForcedCity) {
        columns.push(
          {
            name: 'forceCity',
            label: 'Ville facturation',
          }
        )
      }
      if (this.showEmailCol) {
        columns.push(
          {
            name: 'email',
            label: 'E-mail',
            displayAs: 'email',
          }
        )
      }
      if (this.showCellPhoneCol) {
        columns.push(
          {
            name: 'cellPhone',
            label: 'Tel. portable',
            displayAs: 'phone',
          }
        )
      }
      if (this.showDays) {
        columns.push(
          {
            name: 'daysCount',
            label: 'Journées',
            number: true,
          }
        )
      }
      if (this.showHalfDays) {
        columns.push(
          {
            name: 'halfDaysCount',
            label: '1/2 journées',
            number: true,
          }
        )
      }
      if (this.showMornings) {
        columns.push(
          {
            name: 'morningsCount',
            label: 'Matins',
            number: true,
          }
        )
      }
      if (this.showLunches) {
        columns.push(
          {
            name: 'lunchesCount',
            label: 'Repas',
            number: true,
          }
        )
      }
      if (this.showAfternoons) {
        columns.push(
          {
            name: 'afternoonsCount',
            label: 'Après-midis',
            number: true,
          }
        )
      }
      if (this.fixedFee) {
        columns.push(
          {
            name: 'fixedFeeCount',
            label: 'Forfaits',
            number: true,
          }
        )
      }
      return columns
    },
    items() {
      return this.elements.map(elt => this.makeItem(elt))
    },
    hasDays() {
      return sum(this.elements.map(elt => elt.daysCount))
    },
    showDays() {
      return this.hasDays && existsIn([1], this.selectedMoments)
    },
    hasHalfDays() {
      return sum(this.elements.map(elt => elt.halfDaysCount))
    },
    showHalfDays() {
      return this.hasHalfDays && existsIn([2], this.selectedMoments)
    },
    hasMornings() {
      return sum(this.elements.map(elt => elt.morningsCount))
    },
    showMornings() {
      return this.hasMornings && existsIn([3], this.selectedMoments)
    },
    hasLunches() {
      return sum(this.elements.map(elt => elt.lunchesCount))
    },
    showLunches() {
      return this.hasLunches && existsIn([4], this.selectedMoments)
    },
    hasAfternoons() {
      return sum(this.elements.map(elt => elt.afternoonsCount))
    },
    showAfternoons() {
      return this.hasAfternoons && existsIn([5], this.selectedMoments)
    },
    moments() {
      const moments = []
      if (this.hasDays) {
        moments.push(makeChoice({ id: 1, name: 'Journée', }))
      }
      if (this.hasHalfDays) {
        moments.push(makeChoice({ id: 2, name: '1/2 journées', }))
      }
      if (this.hasMornings) {
        moments.push(makeChoice({ id: 3, name: 'Matins', }))
      }
      if (this.hasLunches) {
        moments.push(makeChoice({ id: 4, name: 'Repas', }))
      }
      if (this.hasAfternoons) {
        moments.push(makeChoice({ id: 5, name: 'Après-midis', }))
      }
      return moments
    },
  },
  mounted() {
    this.loadSeanceFilters()
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    noop() {
      // no operation :-)
    },
    getLinks() {
      let links = []
      const cssDisabled = (this.isLoading(this.statsLoading) || !this.items.length)
      if (this.showList) {
        links = [
          {
            id: 1,
            label: 'Pdf',
            callback: this.printMe,
            icon: 'fa fa-file-pdf',
            cssClass: cssDisabled ? 'btn-secondary disabled' : 'btn-secondary',
          },
          {
            id: 2,
            label: 'Excel',
            callback: this.excelMe,
            icon: 'fa fa-file-excel',
            cssClass: cssDisabled ? 'btn-secondary disabled' : 'btn-secondary',
          }
        ]
        if (this.showEmailCol) {
          links.push(
            {
              id: 3,
              label: 'Emails',
              callback: this.onSend,
              icon: 'fa fa-send',
              cssClass: cssDisabled ? 'btn-disabled' : 'btn-primary',
            }
          )
        }
        if (this.showCellPhoneCol) {
          links.push(
            {
              id: 4,
              label: 'SMS',
              callback: this.onSms,
              icon: 'fas fa-mobile-alt',
              cssClass: cssDisabled ? 'btn-disabled' : 'btn-primary',
            }
          )
        }
      } else {
        links.push(
          {
            id: 5,
            label: 'Retour à la liste',
            callback: this.onReset,
            icon: 'fa fa-chevron-left',
            cssClass: 'btn-secondary',
          }
        )
      }
      return links
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'frequentation-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent, 'dossier_caf')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'frequentation' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      this.startLoading(this.loadingName)
      await this.loadStats()
      this.endLoading(this.loadingName)
    },
    async loadStats() {
      if (this.isValid) {
        this.elements = []
        let url = '/stats/api/youth-attendance-list/'
        let data = {
          'youth_homes': this.youthHomes.map(elt => elt.id),
          'seance_types': this.seanceTypes.map(elt => elt.id),
          'periods': this.periods.map(elt => elt.id),
          'seance_code': this.seanceCodeFilter.replace(',', '|'),
          'workshops': this.workshopsFilter.replace(',', '|'),
        }
        if (this.familyLevel1 || this.familyLevel2) {
          const familyLevel1 = this.familyLevel1 || 0
          const familyLevel2 = this.familyLevel2 || 10000
          data['family_levels'] = [familyLevel1, familyLevel2]
        }
        if (this.startDate) {
          data.start_date = moment(this.startDate).format('YYYY-MM-DD')
        }
        if (this.endDate) {
          data.end_date = moment(this.endDate).format('YYYY-MM-DD')
        }
        if (this.selectedSeanceFilter) {
          data.seance_filter = this.selectedSeanceFilter.id
        }
        if (this.fixedFee) {
          data['fixed_fee'] = true
        }
        if (this.refused) {
          data['refused'] = true
        }
        if (this.waiting) {
          data['waiting'] = true
        }
        if (this.unconfirmed) {
          data['unconfirmed'] = true
        }
        if (this.cancellations) {
          data['cancellations'] = true
        }
        if (this.absence) {
          data['absence'] = true
        }
        if (this.all) {
          data['all'] = true
        }
        const backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi(data)
          this.elements = resp.data.map(elt => makeAttendanceListItem(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onListFilterChanged(evt) {
      this.youthHomes = evt.youthHomes
      this.seanceTypes = evt.seanceTypes
      this.periods = evt.periods
      this.loadData()
    },
    onListFilterLoaded(evt) {
    },
    onSeanceFilterChanged() {
      this.loadData()
    },
    getEntityForceCityName(entity) {
      if (entity.forceCity) {
        return entity.forceCity.name
      }
      return ''
    },
    makeItem(elt) {
      const entityId = elt.entity.id
      return {
        id: '' + elt.individual.id + '-' + entityId,
        lastName: elt.individual.lastName,
        firstName: elt.individual.firstName,
        birthDate: dateToString(elt.individual.birthDate, 'YYYY-MM-DD'),
        entityId: entityId,
        entity: elt.entity,
        address: elt.entity.addressText(),
        zipCode: elt.entity.zipCode,
        city: elt.entity.city.name,
        forceCity: this.getEntityForceCityName(elt.entity),
        daysCount: elt.daysCount,
        halfDaysCount: elt.halfDaysCount,
        morningsCount: elt.morningsCount,
        lunchesCount: elt.lunchesCount,
        afternoonsCount: elt.afternoonsCount,
        fixedFeeCount: elt.fixedFeeCount,
        individual: elt.individual,
        email: elt.individual.email,
        cellPhone: elt.individual.cellPhone,
      }
    },
    onDateFrameChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.loadData()
    },
    async loadSeanceFilters() {
      const backendApi = new BackendApi('get', '/api/youth/invoicing-filters/')
      try {
        let resp = await backendApi.callApi()
        const seanceFilters = resp.data.map(elt => makeChoice(elt))
        if (seanceFilters.length) {
          this.seanceFilters = [makeChoice({ id: 0, name: 'Toutes les séances', })].concat(seanceFilters)
          this.selectedSeanceFilter = this.seanceFilters[0]
        } else {
          this.seanceFilters = []
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onMomentsChanged(event) {
      this.selectedMoments = event.choices.map(elt => elt.id)
    },
    onSend() {
      if (this.items.length) {
        this.showEmailsList = true
      }
    },
    onSms() {
      if (this.items.length) {
        this.smsNumber = this.mobilePhones.length
        this.showCellPhonesList = true
      }
    },
    reset() {
      this.showEmailsList = false
      this.showCellPhonesList = false
    },
    onReset() {
      this.reset()
    },
  },
}
</script>

<style scoped lang="less">
.header-bar {
  background: #e0e0e0;
}
.field-box {
  padding: 8px;
  border: solid 1px #aaa;
  margin-bottom: 10px;
  border-radius: 4px;
}
.field-box-title {
  font-weight: bold;
  padding: 1px 5px;
  background: #aaa;
  margin-bottom: 2px;
}
</style>
